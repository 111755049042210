import React from "react"
import * as styles from './footer.module.scss'
import { Link } from "gatsby"
import footerLogo from '../images/header/rcslogo.png'
import email from '../images/footer/foot-email.png'


import time from '../images/footer/footer-icon-Time.png'
import phone from '../images/footer/footer-icon-phone.png'
import twitter from '../images/footer/footer-icon.png'
import facebook from '../images/footer/footer-icon1.png'
import ins from '../images/footer/footer-icon2.png'
import youtube from '../images/footer/footer_icon3.png'
import Fade from 'react-reveal/Fade';
const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.firstSection}>
                <div className={styles.takeBtn}>
                    <Fade bottom delay={300}>
                        <h2 >Impactful change starts here!</h2>
                    </Fade>
                    <Link to="/about#subm">
                        Contact us
                    </Link>
                </div>
            </div>
            <div className={styles.secondSection}>
                <div className={styles.contain}>
                    <img src={footerLogo} alt="" className={styles.footerlogo} />
                    <div className={styles.contact}>
                        <span>CONTACT US</span>
                        <div className={styles.all}>
                            <div>
                                <img src={email} alt="" />
                                <div className={styles.detail}>
                                    {/* <span>Phone</span>
                                    <p>755 8325 8291</p> */}
                                    <span>Email</span>
                                    <p>info@resiliencecorporateservices.com</p>
                                </div>
                            </div>
                            {/* <div>
                                <img src={time} alt="" />
                                <div className={styles.detail}>
                                    <span>Time</span>
                                    <p>Working day: 09:00-18:00</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.company}>
                    <span>
                        <p>
                            Copyright © 2022  - Resilience Corporate Services. All rights reserved.
                        </p>
                    </span>
                    <ul>
                        <li>
                            <img src={twitter} alt="" />
                        </li>
                        <li>
                            <img src={facebook} alt="" />
                        </li>
                        <li>
                            <img src={ins} alt="" />
                        </li>
                        <li>
                            <img src={youtube} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
export default Footer