import React, { useEffect } from "react"
import * as styles from './header.module.scss'
import { Link } from "gatsby"

import { useState } from 'react'
import whiteLogo from "../images/about/logo-white.svg"
import homeLogo from '../images/header/rcslogo.png'
import twitter from '../images/footer/footer-icon.png'
import facebook from '../images/footer/footer-icon1.png'
import ins from '../images/footer/footer-icon2.png'
import youtube from '../images/footer/footer_icon3.png'
const Header = ({location}) => {
    const dropLinkList = [
        {
            page: 'Home',
            link: '/'
        },
        {
            page: 'Services',
            link: '/services'
        },
        {
            page: 'About',
            link: '/about'
        },
        {
            page: 'Blog',
            link: '/blog'
        }
    ]
    const [open, setOpen] = useState(0)
    const [fade, setFade] = useState(0)
    const [logo,setLogo] = useState(true)
    const turnStatus = () => {
        setOpen(!open)
        setTimeout(() => {
            setFade(!fade)
        }, 300);
    }
    const [rightLink, setRightLink] = useState()
    useEffect(() => {
        window.addEventListener('scroll', handlescroll);
        const routeTo = () => {
            let nowLink = window.location.pathname
            switch (nowLink.toString()) {
                case "/services": setRightLink(1);
                    break;
                case "/about": setRightLink(2);
                    break;
                case "/blog": setRightLink(3);
                    break;
                
                default:
                    setRightLink(0);
            }
        }
        routeTo()
        return () => {
        }
    }, [])
    const url = typeof window !== "undefined"? window.location.pathname : ""
    let screenWidth = typeof window !== "undefined" ? window.screen.width :""
    const [pathName,setPathName] = useState(url)
    const handlescroll = (e)=>{
        let scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
        
        // if(scrollTop>=940){
        //     setLogo(false)
        // }else{
        //     setLogo(true)
        // }
        if(url === "/" && scrollTop>=4300 && scrollTop<=5700 && screenWidth>1024 || url.indexOf("about") !== -1 && scrollTop<938){
            setLogo(false)
        }else if(url === "/" && scrollTop>=5313 && scrollTop<=6535 && screenWidth<1024){
            setLogo(false)
        }else if(url.indexOf("about") !== -1 && scrollTop>=938){
            setLogo(true)
        }else{
            setLogo(true)
        }
    }
    useEffect(()=>{
        if(pathName.indexOf("about") !== -1 ){
            setLogo(false)
        }
    },[pathName])
    return (
        <>
            <header className={styles.top}>
            <Link to="/">
                {/* <img className={styles.logoImg} src={homeLogo} alt="" /> */}
                {/* <img src={  url.indexOf('about') !== -1? logo?whiteLogo:homeLogo: homeLogo} alt="" /> */}
                <img src={logo?homeLogo: whiteLogo} alt="" />
                
            </Link>
            <div className={styles.btnT} >
                <div style={{border:`${logo?"":"1px solid white"}`}} className={open ? `${styles.menuAndBtn} ${styles.menuAndBtn_active}` : `${styles.menuAndBtn}`} onClick={turnStatus}>
                    <span>
                        <p style={{color:`${logo?"":"white"}`}}>MENU</p>
                    </span>
                    <span  onClick={turnStatus}>
                        {/* <div style={{border:`${logo && url.indexOf('about') !== -1?"1px solid white":""}`}} className={styles.line}></div>
                        <div style={{border:`${logo && url.indexOf('about') !== -1?"1px solid white":""}`}} className={styles.line}></div>
                        <div style={{border:`${logo && url.indexOf('about') !== -1?"1px solid white":""}`}} className={styles.line}></div> */}
                        <div style={{border:`${logo ?"":"1px solid white"}`}} className={styles.line}></div>
                        <div style={{border:`${logo ?"":"1px solid white"}`}} className={styles.line}></div>
                        <div style={{border:`${logo ?"":"1px solid white"}`}} className={styles.line}></div>
                    </span>
                </div>

                <div className={open ? `${styles.masks} ${styles.mask_active}` : `${styles.masks} ${styles.mask_before}`} >
                    <div className={styles.main} style={open ? { display: "block" } : { display: "none" }}>
                        {
                            open ?
                                <ul className={fade ? `${styles.tit} ${styles.tit_active}` : `${styles.tit} ${styles.tit_before}`}>
                                    {
                                        dropLinkList.map((item, index) => (
                                            <li key={item.page} className={rightLink === index ? `${styles.linkActive}` : ''}>
                                                <Link to={item.link} onClick={turnStatus}>
                                                    {item.page}
                                                </Link>
                                                <div className={styles.btnLine}></div>
                                            </li>
                                        ))
                                    }
                                    <li>
                                        <ul>
                                            <li>
                                                <img src={twitter} alt="" />
                                            </li>
                                            <li>
                                                <img src={facebook} alt="" />
                                            </li>
                                            <li>
                                                <img src={ins} alt="" />
                                            </li>
                                            <li>
                                                <img src={youtube} alt="" />
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                : ''
                        }
                    </div>
                </div>
            </div>
        </header>
        <div onClick={() => {
            document.documentElement.scrollTop = 0
          }} className={styles.toTop}></div>
        </>
    )
}
export default Header