// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--Z1xkR";
export var firstSection = "footer-module--firstSection--OgGSH";
export var takeBtn = "footer-module--takeBtn--FUjG0";
export var secondSection = "footer-module--secondSection--5e4G+";
export var contain = "footer-module--contain--fcHex";
export var footerlogo = "footer-module--footerlogo--kB8zj";
export var contact = "footer-module--contact--B5d1F";
export var all = "footer-module--all--IlQ0A";
export var detail = "footer-module--detail--I4Vsb";
export var line = "footer-module--line--9C-ep";
export var company = "footer-module--company--GoGno";